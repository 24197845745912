import type { FetchError } from 'ofetch'

export default defineNuxtRouteMiddleware(async (to, from) => {
  // don't execute this middleware if navigating from Step 2 => Step 1
  if (from.name === 'EnrollmentWizardStep2Page') {
    return
  }

  // error handler
  const { doHandleError } = useErrorHandler()

  // router
  const router = useRouter()

  // stores
  const enrollmentStore = useEnrollmentStore()
  const { enrollments } = storeToRefs(enrollmentStore)

  try {
    /**
     * If returning Back-Up Care user, skip step 1
     * and redirect to step 2
     */
    await enrollmentStore.index({
      params: {
        include: 'brand'
      }
    })

    const isReturningBackUpCareUser = Boolean(enrollments.value.find((enrollment) => {
      return enrollment.brand?.name === 'Back-Up Care Tutoring'
    }))

    if (isReturningBackUpCareUser) {
      await router.push(
        `/bright-horizons/enrollment-wizard/${
          to.params.tutorPackageTranscriptId
        }/subject`
      )
    }
  } catch (error) {
    doHandleError(error as FetchError)
  }
})
